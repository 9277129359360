import Vue from 'vue'
import $store from '@/store/index';
import {
	Loading
} from 'element-ui'

const vm = new Vue();

const loading = function () {
	Loading.service({
		fullscreen: true,
		lock: true,
		background: 'rgba(0,0,0,.3)'
	});
}
const close = function () {
	Loading.service().close();
}
const confirm = function (message) {
	return vm.$confirm(message, $store.state.language=='en-GB'|| !$store.state.language?'hint':'Prompt', {
		cancelButtonText: $store.state.language=='en-GB'|| !$store.state.language?'cancel':'cancelar',
		confirmButtonText: $store.state.language=='en-GB'|| !$store.state.language?'confirm modification':'confirmar los cambios',
		cancelButtonClass: 'cancel_btn',
		confirmButtonClass: 'confirm_btn',
	})
}
const notify = function (title, message) {
	return vm.$notify({
		title: title,
		message: message
	});
}
const message = function (title) {
	return vm.$message.warning(title);
}

export default {
	confirm,
	loading,
	close,
	message,
	notify
}