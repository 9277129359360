import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			paths: ['cartTemp'],
			storage: window.sessionStorage,
		}),
	],
	state: {
		platform: 'pc',
		isLogin: false,
		areaList: [],
		cateList: [],
		cartTemp: [],
		cateArr: [],
		orderListCount: 0,
		language: 'es-ES', // es-ES/zh-CN/en-GB
	},
	mutations: {
		setCartTemp(state, value) {
			state.cartTemp = value;
		},
	},
	actions: {},
	modules: {}
})
